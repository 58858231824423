export default {
  // Ações básicas | página de relatório
  "index": "Ver Listagem",
  "create": "Adicionar",
  "update": "Editar",
  "delete": "Excluir",
  "depara": "Fazer De->Para",
  "aprovar": "Aprovar",
  "reprovar": "Reprovar",
  "revisao-aprovada": "Aprovar Revisão",
  "revisao-reprovada": "Reprovar Revisão",
  "plurianual": "Gerar Plurianual",
  "duplicar": "Duplicar Encargos",
  "calculo-finalizar": "Fechar Cálculo",
  "calculo-abrir": "Abrir Cálculo",
  "calculo-executar": "Cálcular",
  "ver-empresas": "Ver Empresas",
  "ajustar-horas": "Ajustar Horas",
  "reimportar": "Re-importar",
  "incremento": "Cálculo Incremento",
  "incrementoDetalhado": "Cálculo Incremento Detalhado",
  "relatorios-exportados": "Relatórios Exportados",
  "status-e-inconsistencias": "Status e Inconsistencias",
  "grupoDeEncargos": "Grupo de Encargos",
  "unidades": "Unidades",
  "dedicacao": "Dedicação",
  "tipoRH": "Tipo RH",
  "valorAproveitadoIncentivo": "Valor Aproveitado de Incentivo",
  "gastosComMateriais": "Gastos com Materiais",
  "gastosComTerceiros": "Gastos com Terceiros",
  "materiaisDetalhado": "Materiais Detalhados",
  "servicosTerceirosDetalhados": "Serviços Terceiros Detalhados",
  "resumoDeGastosPorProjetos": "Resumo de Gastos por Projetos",
  "resumoDePED": "Resumo de P&D",
  "resumoRHProjeto": "Resumo de RH por Projeto",
  "porProjeto": "por Projeto",
  "porColaborador": "por Colaborador",
  "resumoDePEDLIParaRDA": "Resumo de P&D LI para RDA",
  "resumoDoFaturamentoIncentivado": "Resumo do Faturamento Incentivado",
  "elaboracao": "Elaboração",
  "usuariosDoSistema": "Usuários do Sistema",
  "catalogoDeItensComplementar": "Catalogo de Itens Complementar",
  "notasFiscaisCanceladas": "Notas Fiscais Canceladas",
  "catalogosDeItens": "Catálogos de Itens",
  "produtosIncentivados": "Produtos Incentivados",
  "xml": "XML",
  "atualizacaoDeColaboradores": "Atualização de Colaboradores",
  "controleDePonto": "Controle de Ponto",
  "provisoes": "Provisões",
  "planilhaDeConsumoDeMateriais": "Planilha de Consumo de Materiais",
  "planilhaDeMateriais": "Planilha de Materiais",
  "planilhaDeTerceiros": "Planilha de Terceiros",
  "anexosDeMateriais": "Anexos de Materiais",
  "anexosDeServicosDeTerceiros": "Anexos de Serviços de Terceiros",
  "razaoContabil": "Razão Contábil",
  "abrir": "Abrir",
  "fechar": "Fechar",
  "geral": "Geral",
  "consumoDetalhado": "Consumo Detalhado",
  "cumprimentoDeObrigacaoDePED": "Cumprimento de Obrigação de P&D",
  "resumoCreditoFinanceiroTrimestral": "Resumo de Crédito Financeiro Trimestral",
  "timesheetUpdate": "Atualização em lote",
  "anexos-li": "Anexos de Lei de Informática",
  "anexos-contratos": "Anexos de Contratos",
  "anexos-convenios": "Anexos de Convênios",

  // Submódulos
  "acesso": "Controle de Acesso",
  "agrupamentos": "Agrupamentos",
  "usuarios": "Usuários",
  "clientes": "Empresas",
  "grupoDeEmpresas": "Grupo de Empresas",
  "consultores": "Consultores",
  "folhaDePagamento": "Folha de Pagamento",
  "analise": "Análise",
  "enquadrados": "Enquadrados",
  "consumoMateriais": "Consumo de Materiais",
  "equipamentosSoftwares": "Equipamentos/Softwares",
  "grupoEncargos": "Grupos de Encargos",
  "faturamento": "Faturamento",
  "materiais": "Materiais",
  "pareamentoRubricas": "Pareamento de Rubricas",
  "recursosHumanos": "Recursos Humanos",
  "rubricasPadrao": "Rubricas Padrão do Sistema",
  "servicosTerceiros": "Serviços Terceiros",
  "colaboradoresPorProjetos": "Colaboradores por Projetos",
  "etapasAtividades": "Etapas/Atividades",
  "colaboradores": "Colaboradores",
  "logDeHoras": "Log de Horas",
  "dispendios": "Dispêndios",
  "faturamentoIncentivado": "Faturamento Incentivado",
  "pesquisadores": "Pesquisadores",
  "lalur": "LALUR",
  "fornecedoresTerceiros": "Fornecedores e Terceiros",
  "alocacaoDeHoras": "Alocação de Horas",
  "timesheets": "Timesheets",
  "timesheet": "Timesheet",
  "calculos": "Cálculos",
  "calculo": "Cálculo",

  // Módulos
  "Geral": "Geral",
  "Cadastro": "Cadastro",
  "Dashboard": "Dashboards e Relatórios",
  "Colaboradores": "Colaboradores",
  "Projetos": "Projetos",
  "Dispendios": "Dispêndios",
  "Timesheet": "Timesheet",
  "Faturamento": "Faturamento",
  "Importacoes": "Importações",  
}
